import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import BroadcastForm from './form'
import { useState } from "react";
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../../const";
import { useDispatch } from "react-redux"
import localAxios from "../../axios";
import NotificationHistory from "./history";

const initFormValues = {
    targetPlatform: 'phones',
    title: '',
    body: '',
    imgUrl: '',
    link: '',
    targetBroker: 'zerodha',
    targetClientCodes: [],
    targetApp: 'free'
}

export default function BroadcastNotifications() {
    const dispatch = useDispatch()
    const [status, setStatus] = useState(state_form)
    const [confirmFromDialog, setConfirmFromDialog] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [formValues, setFormValues] = useState(initFormValues)
    const changeFormValue = (e) => {
        setStatus(state_form)
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }
    const openConfirmDialog = () => {
        setConfirmDialogOpen(true)
    }
    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false)
    }
    const resetFormValues = () => {
        setFormValues(initFormValues)
    }

    const handleBraodcast = (e) => {
        e.preventDefault()
        if (formValues.targetClientCodes.length === 0) {
            if (!confirmFromDialog) {
                openConfirmDialog()
                return
            }
        }
        setStatus(state_loading)
        localAxios({
            method: 'post',
            url: '/notif/add',
            data: formValues,
        })
            .then(function (response) {
                setStatus(state_success)
            })
            .catch(function (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                }
                var errMsg = ""
                if (error.response?.data) {
                    errMsg = error.response.data.message
                } else {
                    errMsg = error.message
                }
                setStatus(state_error)
                dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
            })
        setConfirmFromDialog(false)
        setConfirmDialogOpen(false)
    }

    return <Grid
        sx={{
            m: 2,
            p: 2,
            textAlign: 'left'
        }}
        container
    >
        <Grid item xs={6} sx={{ pr: 2 }}>
            <Typography variant="h6">
                Broadcast Notifications
            </Typography>
            <BroadcastForm
                handleBraodcast={handleBraodcast}
                formValues={formValues}
                changeFormValue={changeFormValue}
                status={status}
                resetFormValues={resetFormValues}
            />
            <Dialog onClose={closeConfirmDialog} open={confirmDialogOpen}>
                <DialogTitle>
                    Sending notification to all users
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to send this notification to all users?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button sx={{ textTransform: 'none' }} onClick={closeConfirmDialog}>
                        Cancel
                    </Button>
                    <Button sx={{ textTransform: 'none' }}
                        variant="contained"
                        onClick={(e) => {
                            closeConfirmDialog()
                            setConfirmFromDialog(true)
                            handleBraodcast(e)
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
        <Grid item xs={6} sx={{ pl: 2 }}>
            <NotificationHistory
                setFormValues={setFormValues}
            />
        </Grid>
    </Grid>
}