import { useDispatch, useSelector } from "react-redux"
import { state_action_closesnackbar } from "./const"
import { IconButton, Snackbar } from "@mui/material"
import { Close } from "@mui/icons-material"

export default function LocalSnackbar() {
    const dispatch = useDispatch()
    const snackbar = useSelector(state => state.snackbar)

    const closeSnackbar = () => {
        dispatch({ type: state_action_closesnackbar })
    }

    const snackbarCloseHandler = <IconButton
        color="inherit"
        onClick={closeSnackbar}
    >
        <Close fontSize="small" />
    </IconButton>


    return <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        message={snackbar.message}
        action={snackbarCloseHandler}
    />
}