import { Box,  Typography } from "@mui/material";

export default function TodayValues(props) {
    return <Box sx={{width: '100%', mt:8}}>
        <Typography variant="body1">
            <strong>Total Orders Today : </strong>{props.todayValues?.order_placed_today} <br />
            <strong>Complete Orders Today : </strong>{props.todayValues?.order_completed_today} <br />
            <strong>Paper Orders Today : </strong>{props.todayValues?.paper_orders_today} <br />
            <strong>Live Orders Today : </strong>{props.todayValues?.live_orders_today} <br />
            <strong>Active Deployments Today : </strong>{props.todayValues?.active_deployments_today} <br />
        </Typography>
    </Box>
}