import { Box, Button, Paper, Skeleton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../../const";
import localAxios from "../../axios";
import { useDispatch } from "react-redux";


export default function NotificationHistory(props) {
    const [status, setStatus] = useState(state_form)
    const [history, setHistory] = useState([])
    const dispatch = useDispatch()

    const notificationCopyHandler = (item) => {
        item.targetBroker = 'zerodha'
        item.targetPlatform = 'all'
        props.setFormValues(item)
    }

    const fetchNotificationHistory = () => {
        setStatus(state_loading)
        setHistory([])

        localAxios({
            method: 'get',
            url: '/notif/history',
        })
            .then(function (response) {
                console.log(response)
                setHistory(response.data)
                setStatus(state_success)
            })
            .catch(function (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                    setHistory([])
                    setStatus(state_error)
                }
            })
    }

    useEffect(() => {
        fetchNotificationHistory()
    }, [])

    const renderNotificationHistory = () => {
        if (status === state_error) {
            return <Box sx={{ mt: 3 }}>
                <Typography variant='body2' sx={{ color: 'error.main' }}>
                    Error fetching history
                </Typography>
            </Box>
        }
        if (status === state_loading) {
            return <Paper elevation={2} sx={{ mt: 2, p: 2 }}>
                <Skeleton variant="rectangular" width='80%' height={20} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width='100%' height={20} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width='100%' height={20} sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width='100%' height={20} sx={{ mb: 1 }} />
            </Paper>
        }
        return history.map((item, index) => {
            return <Tooltip placement='left' title='Click to copy' key={index}>
                <Paper
                    elevation={2}
                    sx={{ mt: 2, p: 2, textAlign: 'left' }}
                    key={index}
                    component={Button}
                    onClick={() => notificationCopyHandler(item)}
                >
                    <Typography variant="body1" sx={{ textTransform: 'none' }}>
                        <strong>Title : </strong> {item.title} <br />
                        <strong>Body : </strong> {item.body} <br />
                        <strong>Image Url : </strong> {item.imgUrl} <br />
                        <strong>Link : </strong> {item.link} <br />
                        <strong>Target Broker : </strong> {item.targetBroker} <br />
                        <strong>Target Client Codes : </strong> {item.targetClientCodes} <br />
                        <strong>Target App : </strong> {item.targetApp} <br />
                        <strong>Created By : </strong> {item.createdBy} <br />
                        <strong>Target Platform : </strong> {item.targetPlatform} <br />
                    </Typography>
                </Paper>
            </Tooltip>
        })
    }

    return <Box sx={{ pl: 2 }}>
        <Typography variant='h6'>
            Notifications History (24h)
        </Typography>
        {renderNotificationHistory()}
    </Box>
}