export const state_action_login = 'LOGIN'
export const state_action_logout = 'LOGOUT'
export const state_action_opensnackbar = 'OPEN_SNACKBAR'
export const state_action_closesnackbar = 'CLOSE_SNACKBAR'

export const state_loading = 'LOADING'
export const state_form = 'FORM'
export const state_error = 'ERROR'
export const state_success = 'SUCCESS'

export const length_sidebarWidth = 240

export const route_dashboard = '/dashboard'
export const route_metrics = '/metrics'
export const route_pro_metrics = '/pro_metrics'
export const route_notifiactions = '/notifications'
export const route_notifiactionsBroadcast = route_notifiactions + '/broadcast'
export const route_repeatUsers = '/repeating_users'
