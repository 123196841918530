import { Box, Grid, Link, Paper, Typography } from "@mui/material";

export default function Lost() {
    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            mt:20,
        }}
    >
        <Paper
            elevation={2}
            sx={{
                p: 5
            }}
        >
            <Typography variant="h4" gutterBottom>
                You seem lost.
            </Typography>
            <Link
                href="/dashboard"
                sx={{
                    textDecoration: 'none',
                    color: 'primary.main',
                    mt: 2,
                }}
            >
                Back to Homepage
            </Link>
        </Paper>
        <Box sx={{ width: '100%', height: '150px' }} />
    </Grid>
}