import { TableCell, TableRow } from "@mui/material"

export default function UsersTableRow(props) {
    return <TableRow
        key={props.cells.date}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }
        }
    >
        <TableCell component="th" scope="row">
            {props.cells[0].date}
        </TableCell>
        {
            props.cells.map((eachCell, index) => {
                return <TableCell key={index} align="center">
                    {eachCell.count === "" ? "-" : eachCell.count}
                </TableCell>
            })
        }
    </TableRow >
}