import { Box, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../const";
import { useDispatch } from "react-redux";
import localAxios from "../axios";
import AppButton from "../components/button";
import UsersList from "./usersList";

const emptyResponse = {
    users_logged_in_today: 0,
    users_with_active_subscription: 0,
    users_with_active_subscription_list: [],
}

export default function Users() {
    const [status, setStatus] = useState(state_form)
    const dispatch = useDispatch()
    const [response, setResponse] = useState(emptyResponse)

    const getUsers = () => {
        setStatus(state_loading)
        setResponse(emptyResponse)

        localAxios({
            method: 'get',
            url: '/pro_metrics'
        })
            .then(function (resp) {
                console.log(resp)
                setStatus(state_success)
                setResponse(resp.data)
            })
            .catch(function (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                    setResponse(emptyResponse)
                    setStatus(state_error)
                }
            })
    }

    const renderResponse = () => {
        if (status === state_success || (status === state_form && response.alerts_count !== 0 && response.alerts_count !== undefined && response.alerts_count !== null)) {
            return <Typography variant='body1' sx={{ mt: 3 }}>
                <strong>Users Logged In Today: </strong> {response.users_logged_in_today}
                <br />
                {
                    response.user_logged_in_by_broker && Object.keys(response.user_logged_in_by_broker).map(broker => {
                        return <span key={broker}>
                            <strong>{broker}: </strong>{response.user_logged_in_by_broker[broker]}
                            <br />
                        </span>
                    })
                }
                <br />
                <Divider />
                <br />
                <strong>Users With Active Subscription: </strong>{response.users_with_active_subscription}
                <br />
                {
                    response.users_with_active_subscription_by_broker && Object.keys(response.users_with_active_subscription_by_broker).map(broker => {
                        return <span key={broker}>
                            <strong>{broker}: </strong>{response.users_with_active_subscription_by_broker[broker]}
                            <br />
                        </span>
                    })
                }
            </Typography>
        }
        if (status === state_error) {
            return <Typography variant='body1' sx={{ mt: 3 }} color='error'>
                Something went wrong
            </Typography>
        }
        return <></>
    }


    return <Box sx={{ width: '100%' }}>
        <Typography variant="h5">
            Pro Users
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ pt: 3 }}
        >
            <AppButton
                variant="contained"
                sx={{
                    borderRadius: 10,
                    textTransform: 'none',
                    width: 125,
                    height: 40,
                    ml: 2,
                }}
                onClick={getUsers}
                status={status}
                form_text="Fetch"
                disabled={status !== state_form}
            />
        </Grid>
        {renderResponse()}
        <Divider sx={{ mt: 3, mb: 5 }} />
        {
            status === state_success &&
            <UsersList usersList={response.users_with_active_subscription_list} />
        }
    </Box>
}