import { Add, CloudUpload, Delete } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import AppButton from "../../components/button";
import { state_form } from "../../const";
import UploadDialog from "./uploadDialog";

export default function BroadcastForm(props) {
    const [toAddClientCode, setToAddClientCode] = useState('')
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
    const addClientCode = () => {
        if (toAddClientCode.length > 0) {
            props.changeFormValue({
                target: {
                    name: 'targetClientCodes',
                    value: [...props.formValues.targetClientCodes, toAddClientCode]
                }
            })
            setToAddClientCode('')
        }
    }
    const removeClientCode = (clientCode) => {
        props.changeFormValue({
            target: {
                name: 'targetClientCodes',
                value: props.formValues.targetClientCodes.filter(cc => cc !== clientCode)
            }
        })
    }

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={props.handleBraodcast}
        sx={{
            width: '100%',
            maxWidth: '600px',
        }}
    >
        <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="broadcast-notifications-input-target-platform-label">Target Platforms</InputLabel>
            <Select
                labelId="broadcast-notifications-input-target-platform-label"
                id="broadcast-notifications-input-target-platform"
                value={props.formValues.targetPlatform}
                label="Target Platforms"
                name="targetPlatform"
                onChange={props.changeFormValue}
            >
                <MenuItem value={'phones'}>All Phones</MenuItem>
                <MenuItem value={'android'}>Android</MenuItem>
                <MenuItem value={'ios'}>iOS</MenuItem>
                <MenuItem value={'web'}>web</MenuItem>
                <MenuItem value={'all_devices'}>All Devices</MenuItem>
            </Select>
        </FormControl>
        <TextField
            fullWidth
            required
            id="broadcast-notifications-input-title"
            label="Title"
            sx={{ mt: 2 }}
            value={props.formValues.title}
            name="title"
            onChange={props.changeFormValue}
        />
        <TextField
            fullWidth
            required
            id="broadcast-notifications-input-body"
            label="Body"
            sx={{ mt: 2 }}
            value={props.formValues.body}
            name="body"
            onChange={props.changeFormValue}
        />
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <TextField
                fullWidth
                required
                id="broadcast-notifications-input-imgUrl"
                label="Image URL"
                sx={{ mt: 2, flexGrow: 1 }}
                value={props.formValues.imgUrl}
                name="imgUrl"
                onChange={props.changeFormValue}
            />
            <IconButton
                color="primary"
                aria-label="broadcast-notifications-input-upload"
                onClick={() => setUploadDialogOpen(true)}
                sx={{ ml: 2, mt: 1 }}
            >
                <CloudUpload />
            </IconButton>
        </Box>
        <UploadDialog
            uploadDialogOpen={uploadDialogOpen}
            setUploadDialogOpen={setUploadDialogOpen}
            changeFormValue={props.changeFormValue}
        />
        <TextField
            fullWidth
            required
            id="broadcast-notifications-input-link"
            label="Link"
            sx={{ mt: 2 }}
            value={props.formValues.link}
            name="link"
            onChange={props.changeFormValue}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="broadcast-notifications-input-target-broker-label">Target Broker</InputLabel>
            <Select
                labelId="broadcast-notifications-input-target-broker-label"
                id="broadcast-notifications-input-target-broker"
                value={props.formValues.targetBroker}
                label="Target Broker"
                name="targetBroker"
                onChange={props.changeFormValue}
            >
                <MenuItem value={'zerodha'}>Zerodha</MenuItem>
            </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="broadcast-notifications-input-target-app-label">Target App</InputLabel>
            <Select
                labelId="broadcast-notifications-input-target-app-label"
                id="broadcast-notifications-input-target-app"
                value={props.formValues.targetApp}
                label="Target App"
                name="targetApp"
                onChange={props.changeFormValue}
            >
                <MenuItem value={'free'}>Free Streak</MenuItem>
                <MenuItem value={'pro'}>Pro Streak</MenuItem>
            </Select>
        </FormControl>
        {
            props.formValues.targetClientCodes.length > 0 && <Paper
                elevation={4}
                sx={{
                    width: '100%',
                    p: 2,
                    mt: 2
                }}
            >
                {(
                    props.formValues.targetClientCodes.length > 0 &&
                    <Typography
                        variant="body1"
                        sx={{
                            mt: 2,
                            textAlign: 'left',
                        }}
                        align="left"
                    >
                        <strong>Target Client Codes</strong>
                    </Typography>
                )}
                {
                    props.formValues.targetClientCodes.map((eachUser, index) => {
                        return <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            key={index}
                        >
                            <Typography
                                fullWidth
                                variant="body1"
                                sx={{ flexGrow: 1 }}
                            >
                                {eachUser}
                            </Typography>
                            <IconButton
                                onClick={() => removeClientCode(eachUser)}
                                color="error"
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    })
                }
            </Paper>
        }
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            component="form"
            onSubmit={addClientCode}
        >
            <TextField
                required
                id="broadcast-notifications-input-client-codes"
                label="Target Client Codes"
                sx={{ mt: 2, flexGrow: 1, }}
                value={toAddClientCode}
                name="targetClientCodes"
                onChange={e => setToAddClientCode(e.target.value)}
            />
            <IconButton
                onClick={addClientCode}
                sx={{ mt: 1.5, ml: 1 }}
                color="primary"
            >
                <Add />
            </IconButton>
        </Grid>
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
                mt: 4,
                mb: 0,
            }}
        >
            <Button
                sx={{ textTransform: 'none' }}
                onClick={props.resetFormValues}
            >
                Reset
            </Button>
            <AppButton
                variant="contained"
                sx={{
                    borderRadius: 10,
                    textTransform: 'none',
                    // pr: 4, pl: 4, pt: 1, pb: 1,
                    width: 125,
                    height: 40,
                }}
                onClick={props.handleBraodcast}
                status={props.status}
                form_text="Submit"
                disabled={props.status !== state_form}
            />
        </Grid>
    </Grid>
}
