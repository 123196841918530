import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

export default function LoggedInUsersTable(props) {
    if (props.loggedInUsers === undefined || props.loggedInUsers === null || props.loggedInUsers.length === 0) {
        return <></>
    }

    return <Box
        sx={{
            mt: 5
        }}
    >
        <Typography variant="h5">Logged in users</Typography>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ minWidth: 105 }}>
                        Hour
                    </TableCell>
                    {
                        props.loggedInUsers.map((user, index) => {
                            return <TableCell key={index} >
                                {user.hour}
                            </TableCell>
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                    key={0}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }
                    }
                >
                    <TableCell>
                        Users Count
                    </TableCell>
                    {
                        props.loggedInUsers.map((user, index) => {
                            return <TableCell key={index} >
                                {user.count}
                            </TableCell>
                        })
                    }
                </TableRow>
            </TableBody>
        </Table>
    </Box>
}