import { useState } from "react";
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../../const";
import { useDispatch } from "react-redux";
import { Grid, Paper, Typography } from "@mui/material";
import localAxios from "../../axios";
import ProgressBar from "../../components/progressBar";
import AppButton from "../../components/button";

export default function EachNotification(props) {
    const { item } = props
    const [stopStatus, setStopStatus] = useState(state_form)
    const dispatch = useDispatch()

    const stopNotification = () => {
        setStopStatus(state_loading)

        localAxios({
            method: 'delete',
            url: '/notif/' + item.id,
        })
            .then(function (response) {
                setStopStatus(state_success)
                setTimeout(() => {
                    props.fetchNotifsList()
                }, 1000)
            })
            .catch(function (error) {
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    setStopStatus(state_error)
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                }
            })
    }

    return <Paper
        elevation={3}
        sx={{
            textTransform: 'none',
            p: 2,
            mt: 2,
            display: 'block',
            textAlign: 'left',
            width: '100%',
        }}
    >
        <Typography variant="body1" gutterBottom>
            <strong>Title: </strong>{item.title}
        </Typography>
        <Typography variant="body2" sx={{ mb: 3 }}>
            <strong>Body: </strong>{item.body}
            <br />
            <strong>Link: </strong>{item.link}
            <br />
            <strong>Image URL: </strong>{item.imgUrl}
            <br />
            <strong>Target Broker: </strong>{item.targetBroker}
            <br />
            <strong>Target App: </strong>{item.targetApp}
            <br />
            <strong>Created By: </strong>{item.createdBy}
            <br />
            <strong>Target Client Codes: </strong>{item.targetClientCodes.join(', ')}
            <br />
            <strong>Target Platform: </strong>{item.targetPlatform}
            <br />
        </Typography>
        <Typography variant="body2">
            Progress : {item.done_user_count}/{item.total_user_count}
        </Typography>
        <ProgressBar value={(item.done_user_count / item.total_user_count) * 100} />
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >
            <AppButton
                variant="contained"
                sx={{
                    mt: 1,
                    mb: 0,
                    borderRadius: 10,
                    textTransform: 'none',
                }}
                color="error"
                onClick={stopNotification}
                status={stopStatus}
                form_text="Stop"
                disabled={stopStatus !== state_form}
            />
        </Grid>
    </Paper>
}