import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import AppButton from "../components/button";
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../const";
import localAxios from "../axios";
import { useDispatch } from "react-redux";

export default function LiveScreenerAlerts(props) {
    const [status, setStatus] = useState(state_form)
    const dispatch = useDispatch()
    const [response, setResponse] = useState({})

    const searchHandler = () => {
        setStatus(state_loading)

        localAxios({
            method: 'get',
            url: '/live_screener_alerts',
        })
            .then(function (response) {
                console.log(response.data)
                setStatus(state_success)
                setResponse(response.data)
                setTimeout(() => {
                    setStatus(state_form)
                }, 500)
            })
            .catch(function (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }

                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                    setResponse([])
                    setStatus(state_error)
                }
            })
    }

    const renderResponse = () => {
        if (status === state_success || (status === state_form && response.alerts_count !== 0 && response.alerts_count !== undefined && response.alerts_count !== null)) {
            return <Typography variant='body1' sx={{ mt: 3 }}>
                <strong>Live Screener Alerts: </strong> {response.alerts_count}
                <br />
                <strong>Users Count: </strong>{response.users_count}
            </Typography>
        }
        if (status === state_error) {
            return <Typography variant='body1' sx={{ mt: 3 }} color='error'>
                Something went wrong
            </Typography>
        }
        return <></>
    }

    return <Box>
        <Typography variant='h5'>
            Live Screener With Alerts Today
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ pt: 3 }}
        >
            <AppButton
                variant="contained"
                sx={{
                    borderRadius: 10,
                    textTransform: 'none',
                    width: 125,
                    height: 40,
                    ml: 2,
                }}
                onClick={searchHandler}
                status={status}
                form_text="Fetch"
                disabled={status !== state_form}
            />
        </Grid>
        {renderResponse()}
    </Box>
}