import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useState } from "react";
import AppButton from "../components/button";
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../const";
import localAxios from "../axios";
import { useDispatch } from "react-redux";

export default function ScansTopUsers(props) {
    const [status, setStatus] = useState(state_form)
    const dispatch = useDispatch()
    const [response, setResponse] = useState([])
    const [formValues, setFormValues] = useState({
        date: props.todayDate,
        limit: 100,
    })
    const changeFormValues = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value
        })
        setStatus(state_form)
    }

    const searchHandler = () => {
        setStatus(state_loading)

        localAxios({
            method: 'get',
            url: '/scans_top_users?date=' + formValues.date + '&limit=' + formValues.limit,
        })
            .then(function (response) {
                setStatus(state_success)
                setResponse(response.data)
            })
            .catch(function (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }

                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                    setResponse([])
                    setStatus(state_error)
                }
            })
    }

    const renderResponse = () => {
        if (response.length === 0 && status === state_success) {
            return <Typography variant='body1' sx={{ mt: 3 }}>
                No data found
            </Typography>
        }
        if (response.length === 0) {
            return <></>
        }

        return <TableContainer
            component={Paper}
            elevation={0}
            sx={{
                mt: 3,
                borderColor: 'divider',
                border: '1px solid divider'
            }}
        >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            User UUID
                        </TableCell>
                        <TableCell>Scans Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        response.map((eachResponse) => (
                            <TableRow
                                key={eachResponse.userId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }
                                }
                            >
                                <TableCell component="th" scope="row">
                                    {eachResponse.userId}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {eachResponse.count}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    }

    return <Box>
        <Typography variant='h5'>
            Top Scans Users
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ pt: 3 }}
        >
            <TextField
                id="metrics-input-date"
                label="Till Date"
                type="date"
                placeholder=""
                name="date"
                value={formValues.date}
                onChange={changeFormValues}
                sx={{
                    width: 200,
                    mr: 2,
                }}
            />
            <TextField
                id="metrics-input-limit"
                type="number"
                label="Limit"
                name="limit"
                InputProps={{
                    min: 0,
                    max: 100
                }}
                value={formValues.limit}
                onChange={changeFormValues}
                sx={{
                    width: 200,
                    mr: 2,
                }}
            />
            <AppButton
                variant="contained"
                sx={{
                    borderRadius: 10,
                    textTransform: 'none',
                    width: 125,
                    height: 40,
                    ml: 2,
                }}
                onClick={searchHandler}
                status={status}
                form_text="Search"
                disabled={status !== state_form}
            />
        </Grid>

        {renderResponse()}
    </Box>
}