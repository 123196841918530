import { Box, Divider, Typography } from "@mui/material"
import MetricsForm from "./form"
import { useState } from "react"
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../const"
import ResponseTable from "./table"
import { useDispatch } from "react-redux"
import TodayValues from "./todayValues"
import localAxios from "../axios"
import BacktestTopUsers from "./backtestTopUsers"
import ScansTopUsers from "./scansTopUsers"
import LoggedInUsersTable from "./loggedInUsersTable"
import LiveScreenerAlerts from "./liveAlerts"

const defaultTodayValues = {
    order_placed_today: 0,
    order_completed_today: 0,
    paper_orders_today: 0,
    live_orders_today: 0,
}

// const sampleResponse = [
//     {
//         date: '2024-01-24',
//         total_page_visits: 1234,
//         user_logins: 1234,
//         new_user_logins: 1234,
//         repeating_users: 1234,
//         screeners_created: 1234,
//         scans_count: 1234,
//         backtest_ran: 1234,
//         deployments_created: 1234,
//         algos_created: 1234,
//         orders_placed: 1234,
//         paper_orders_placed: 1234,
//     },
//     {
//         date: '2024-01-23',
//         total_page_visits: 1234,
//         user_logins: 1234,
//         new_user_logins: 1234,
//         repeating_users: 1234,
//         screeners_created: 1234,
//         scans_count: 1234,
//         backtest_ran: 1234,
//         deployments_created: 1234,
//         algos_created: 1234,
//         orders_placed: 1234,
//         paper_orders_placed: 1234,
//     },
//     {
//         date: '2024-01-22',
//         total_page_visits: 1234,
//         user_logins: 1234,
//         new_user_logins: 1234,
//         repeating_users: 1234,
//         screeners_created: 1234,
//         scans_count: 1234,
//         backtest_ran: 1234,
//         deployments_created: 1234,
//         algos_created: 1234,
//         orders_placed: 1234,
//         paper_orders_placed: 1234,
//     },
//     {
//         date: '2024-01-21',
//         total_page_visits: 1234,
//         user_logins: 1234,
//         new_user_logins: 1234,
//         repeating_users: 1234,
//         screeners_created: 1234,
//         scans_count: 1234,
//         backtest_ran: 1234,
//         deployments_created: 1234,
//         algos_created: 1234,
//         orders_placed: 1234,
//         paper_orders_placed: 1234,
//     },
// ]

// const sampleLoggedInUsers = [
//     {
//         hour: '14',
//         count: 1234,
//     },
//     {
//         hour: '13',
//         count: 1234,
//     },
//     {
//         hour: '12',
//         count: 1234,
//     },
// ]

export default function Metrics() {
    const [status, setStatus] = useState(state_form)
    const today = new Date()
    const todayDate = today.toISOString().split('T')[0]
    const [reqValues, setReqValues] = useState({
        date: todayDate,
        limit: 10,
        chartView: false,
    })
    const changeReqValue = (e) => {
        setStatus(state_form)
        setReqValues({ ...reqValues, [e.target.name]: e.target.value })
    }
    const dispatch = useDispatch()
    const changeChartView = (e) => {
        setReqValues({ ...reqValues, chartView: e.target.checked })
    }
    const [response, setResponse] = useState([])
    const [todayValues, setTodayValues] = useState(defaultTodayValues)
    const [loggedInUsers, setLoggedInUsers] = useState([])

    const searchHandler = () => {
        setStatus(state_loading)
        setResponse([])

        localAxios({
            method: 'get',
            url: '/metrics?date=' + reqValues.date + '&limit=' + reqValues.limit,
        })
            .then(function (response) {
                console.log(response)
                setStatus(state_success)
                setResponse(response.data?.metrics)
                console.log(response.data)
                setTodayValues({
                    order_placed_today: response.data?.order_placed_today,
                    order_completed_today: response.data?.order_completed_today,
                    paper_orders_today: response.data?.paper_orders_today,
                    live_orders_today: response.data?.live_orders_today,
                    active_deployments_today: response.data?.active_deployments_today,
                })
                setLoggedInUsers(response.data?.logged_in_users_today)
            })
            .catch(function (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                    setResponse([])
                    setTodayValues(defaultTodayValues)
                    setStatus(state_error)
                    setLoggedInUsers([])
                }
            })
    }

    const renderResponse = () => {
        if (reqValues.chartView) {
            return <></>
        } else {
            return <ResponseTable
                response={response}
            />
        }
    }

    return <Box
        sx={{
            textAlign: 'left',
            p: 3,
        }}
    >
        <Typography variant="h5">
            Daily Metrics
        </Typography>
        <MetricsForm
            reqValues={reqValues}
            changeReqValue={changeReqValue}
            searchHandler={searchHandler}
            status={status}
            changeChartView={changeChartView}
        />
        {renderResponse()}
        <LoggedInUsersTable loggedInUsers={loggedInUsers} />
        {
            todayValues?.order_placed_today > 0 ?
                <>
                    <Divider sx={{ mt: 3, mb: 5 }} />
                    <Typography variant="h5">
                        Live Data
                    </Typography>
                    <TodayValues todayValues={todayValues} />
                </> : <></>
        }
        <Divider sx={{ mt: 3, mb: 5 }} />
        <BacktestTopUsers
            todayDate={todayDate}
        />
        <Divider sx={{ mt: 3, mb: 5 }} />
        <ScansTopUsers
            todayDate={todayDate}
        />
        <Divider sx={{ mt: 3, mb: 5 }} />
        <LiveScreenerAlerts />
    </Box>
}