import axios from "axios"
import { store } from './globalState'

// const url = 'http://localhost:8080' // local
// const url = 'https://os-analytics.streak.ninja' // ninja
const url = 'https://os-analytics.streak.tech' // prod


const localAxios = axios.create({
    baseURL: url,
    timeout: 60000, // 60 seconds
})


// store
store.subscribe(listener)

function getToken(state) {
    return state.userDetails.token
}

function listener() {
    localAxios.defaults.headers.common['Authorization'] = getToken(store.getState())
}



export default localAxios
