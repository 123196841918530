import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FileDownload } from "@mui/icons-material";



export default function UsersList(props) {
	const [expanded, setExpanded] = useState(true)
	const handleExpandClick = () => {
		setExpanded(!expanded)
	}
	const usersList = props.usersList

	const downloadDataInFile = () => {
		const csv = usersList.map(row => {
			return Object.values(row).join(',')
		})
		const csvString = csv.join('\n')
		const blob = new Blob([csvString], { type: 'text/csv' })
		const url = URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.href = url
		link.download = 'users.csv'
		link.click()
		URL.revokeObjectURL(url)
	}


	const render = () => {
		if (usersList.length === 0) {
			return <Typography variant="body1">No users with active Subscription</Typography>
		}

		return <TableContainer >
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>User Broker ID</TableCell>
						<TableCell>User Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Phone Number</TableCell>
						<TableCell>Subscription Validity</TableCell>
						<TableCell>Broker</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{usersList.map((row) => (
						<TableRow
							key={row.user_uuid}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component="th" scope="row">
								{row.user_broker_id}
							</TableCell>
							<TableCell>{row.first_name} {row.last_name}</TableCell>
							<TableCell>{row.email}</TableCell>
							<TableCell>{row.phone_number}</TableCell>
							<TableCell>{row.subscription_validity}</TableCell>
							<TableCell>{row.first_broker}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	}

	return <Box>
		<Box sx={{ width: '100%', display: 'flex' }}>
			<Typography variant="h5" sx={{ flexGrow: 1 }}>Users List</Typography>
			<IconButton onClick={downloadDataInFile} color="primary">
				<FileDownload />
			</IconButton>
		</Box>
		{render()}
	</Box>

}