import { TableCell, TableRow } from "@mui/material"


// function DialogSplitView(props) {
//     return <Grid
//         container
//         sx={{
//             p: 2,
//             width: 400,
//         }}
//     >
//         <Grid item xs={6}>
//             <Typography variant="body1" gutterBottom>
//                 {props.titleOne}
//             </Typography>
//             <Typography variant="body2">
//                 {props.valueOne}
//             </Typography>
//         </Grid>
//         <Grid item xs={6}>
//             <Typography variant="body1" gutterBottom>
//                 {props.titleTwo}
//             </Typography>
//             <Typography variant="body2">
//                 {props.valueTwo}
//             </Typography>
//         </Grid>
//     </Grid>
// }


export default function ResponseTableRow(props) {
    // const [dialogOpen, setDialogOpen] = useState(false)
    // const handleOpenDialog = () => {
    //     setDialogOpen(true)
    // }
    // const handleCloseDialog = () => {
    //     setDialogOpen(false)
    // }
    // const [dialogContent, setDialogContent] = useState({
    //     title: "",
    //     content: <></>
    // })

    // const dialog = <Dialog
    //     open={dialogOpen}
    //     onClose={handleCloseDialog}
    //     aria-labelledby="metrics-dialog-title"
    //     aria-describedby="metrics-dialog-shows-data"
    // >
    //     <DialogTitle id="metrics-dialog-title">
    //         {dialogContent.title}
    //     </DialogTitle>
    //     <DialogContent>
    //         {dialogContent.content}
    //     </DialogContent>
    // </Dialog>

    return <TableRow
        key={props.eachResponse.date}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }
        }
    >
        <TableCell component="th" scope="row">
            {props.eachResponse.date}
        </TableCell>
        {/* <TableCell align="center">{props.eachResponse.web_visits}</TableCell>
            <TableCell align="center">{props.eachResponse.app_visits}</TableCell> */}
        <TableCell align="center">{props.eachResponse.web_users}</TableCell>
        <TableCell align="center">{props.eachResponse.app_users}</TableCell>
        <TableCell align="center">{props.eachResponse.total_logins}</TableCell>
        <TableCell align="center">{props.eachResponse.new_logins}</TableCell>
        <TableCell align="center">{props.eachResponse.repeat_logins}</TableCell>
        <TableCell align="center">{props.eachResponse.repeat_logins_7_days}</TableCell>
        <TableCell align="center">{props.eachResponse.scanners_created}</TableCell>
        <TableCell align="center">{props.eachResponse.scans_count}</TableCell>
        <TableCell align="center">{props.eachResponse.scan_symbols_count}</TableCell>
        <TableCell align="center">{props.eachResponse.screener_alerts_created}</TableCell>
        <TableCell align="center">{props.eachResponse.screener_alerts_creating_users}</TableCell>
        {/* <TableCell align="center">{props.eachResponse.live_screener_alerts}</TableCell> */}
        <TableCell align="center">{props.eachResponse.strategies_created}</TableCell>
        <TableCell align="center">{props.eachResponse.backtest_ran}</TableCell>
        <TableCell align="center">{props.eachResponse.deployments_created}</TableCell>
        <TableCell align="center">{props.eachResponse.deployment_creating_users}</TableCell>
        <TableCell align="center">{props.eachResponse.live_deployments}</TableCell>
        <TableCell align="center">{props.eachResponse.orders_placed}</TableCell>
        <TableCell align="center">{props.eachResponse.order_placing_users}</TableCell>
        <TableCell align="center">{props.eachResponse.paper_orders_placed}</TableCell>
        <TableCell align="center">{props.eachResponse.live_orders_placed}</TableCell>
    </TableRow >
}