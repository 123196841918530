import { CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./globalState";
import { lightTheme } from './theme'
import Router from "./router";

export default function AppRoot() {
    return <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <Router />
        </ThemeProvider>
    </Provider>
}