import { useEffect, useState } from "react";
import { state_action_logout, state_action_opensnackbar } from "../../const";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import localAxios from "../../axios"
import EachNotification from "./eachNotification";

// const sampleData = [
//     {
//         targetPlatform: 'phones',
//         title: 'Get Free Access',
//         body: 'Get Free Access',
//         imgUrl: 'some link',
//         link: 'some link',
//         targetBroker: 'zerodha',
//         targetClientCodes: [
//             'abcd',
//             'abcd',
//             'abcd',
//         ],
//         targetApp: 'targetApp',
//         createdBy: 'createdBy',
//     }
// ]

export default function NotificationsList() {
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    const fetchHandler = () => {
        localAxios({
            method: 'get',
            url: '/notif/',
        })
            .then(function (response) {
                console.log("response : ", response)
                setData(response.data)
            })
            .catch(function (error) {
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                }
            })
    }

    useEffect(() => {
        fetchHandler()
    }, [])

    if (data.length === 0) {
        return <Typography variant="h6">
            No Active Notifications
        </Typography>
    }

    return <>
        <Typography variant="h6">
            Active Notifications
        </Typography>
        {
            data.map((item, index) => {
                return <EachNotification
                    item={item}
                    key={index}
                    fetchNotifsList={fetchHandler}
                />
            })
        }
    </>
}