import { Grid } from "@mui/material";
import Form from "./form";
import { useState } from "react";
import { state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../const";
import { useDispatch } from "react-redux";
import localAxios from "../axios";
import ResponseTable from "./table";

export default function RepeatingUsers() {
    const dispatch = useDispatch()
    const [status, setStatus] = useState(state_form)
    const [reqValues, setReqValues] = useState({
        chart_type: 'daily',
    })
    const changeReqValue = (e) => {
        setStatus(state_form)
        setReqValues({ ...reqValues, [e.target.name]: e.target.value })
    }
    const [response, setResponse] = useState([])
    const changeFormValue = (e) => {
        setStatus(state_form)
        setReqValues({ ...reqValues, [e.target.name]: e.target.value })
    }

    const searchHandler = () => {
        setStatus(state_loading)
        setResponse([])

        localAxios({
            method: 'get',
            url: '/repeat_users?chart_type=' + reqValues.chart_type,
        })
            .then(function (response) {
                console.log(response)
                setStatus(state_success)
                setResponse(response.data?.rows)
            })
            .catch(function (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                    setResponse([])
                    setStatus(state_error)
                }
            })
    }

    return <Grid container
        sx={{
            textAlign: 'left',
            p: 3,
        }}
    >
        <Form
            reqValues={reqValues}
            changeReqValue={changeReqValue}
            searchHandler={searchHandler}
            status={status}
            changeFormValue={changeFormValue}
        />
        <ResponseTable
            response={response}
            chart_type={reqValues.chart_type}
        />
    </Grid>
}

const sampleResponse = [
    {
        "start_date": "",
        "cells": [
            {
                "date": "2024-01-26",
                "interval": "D00",
                "count": "0"
            },
            {
                "date": "2024-01-27",
                "interval": "D01",
                "count": "0"
            },
            {
                "date": "2024-01-28",
                "interval": "D02",
                "count": "0"
            },
            {
                "date": "2024-01-29",
                "interval": "D03",
                "count": "0"
            },
            {
                "date": "2024-01-30",
                "interval": "D04",
                "count": "0"
            },
            {
                "date": "2024-01-31",
                "interval": "D05",
                "count": "0"
            },
            {
                "date": "2024-02-01",
                "interval": "D06",
                "count": "0"
            }
        ]
    },
    {
        "start_date": "",
        "cells": [
            {
                "date": "2024-01-27",
                "interval": "D00",
                "count": "0"
            },
            {
                "date": "2024-01-28",
                "interval": "D01",
                "count": "0"
            },
            {
                "date": "2024-01-29",
                "interval": "D02",
                "count": "0"
            },
            {
                "date": "2024-01-30",
                "interval": "D03",
                "count": "0"
            },
            {
                "date": "2024-01-31",
                "interval": "D04",
                "count": "0"
            },
            {
                "date": "2024-02-01",
                "interval": "D05",
                "count": "0"
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            }
        ]
    },
    {
        "start_date": "",
        "cells": [
            {
                "date": "2024-01-28",
                "interval": "D00",
                "count": "0"
            },
            {
                "date": "2024-01-29",
                "interval": "D01",
                "count": "0"
            },
            {
                "date": "2024-01-30",
                "interval": "D02",
                "count": "0"
            },
            {
                "date": "2024-01-31",
                "interval": "D03",
                "count": "0"
            },
            {
                "date": "2024-02-01",
                "interval": "D04",
                "count": "0"
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            }
        ]
    },
    {
        "start_date": "",
        "cells": [
            {
                "date": "2024-01-29",
                "interval": "D00",
                "count": "8441"
            },
            {
                "date": "2024-01-30",
                "interval": "D01",
                "count": "2669"
            },
            {
                "date": "2024-01-31",
                "interval": "D02",
                "count": "1"
            },
            {
                "date": "2024-02-01",
                "interval": "D03",
                "count": "0"
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            }
        ]
    },
    {
        "start_date": "",
        "cells": [
            {
                "date": "2024-01-30",
                "interval": "D00",
                "count": "7742"
            },
            {
                "date": "2024-01-31",
                "interval": "D01",
                "count": "0"
            },
            {
                "date": "2024-02-01",
                "interval": "D02",
                "count": "0"
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            }
        ]
    },
    {
        "start_date": "",
        "cells": [
            {
                "date": "2024-01-31",
                "interval": "D00",
                "count": "2"
            },
            {
                "date": "2024-02-01",
                "interval": "D01",
                "count": "0"
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            }
        ]
    },
    {
        "start_date": "",
        "cells": [
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            },
            {
                "date": "",
                "interval": "",
                "count": ""
            }
        ]
    }
]