import { Grid, Switch, TextField, Typography } from "@mui/material"
import AppButton from '../components/button'
import { state_form } from "../const"

export default function MetricsForm(props) {

    return <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ pt: 3 }}
    >
        <TextField
            id="metrics-input-date"
            label="Till Date"
            type="date"
            placeholder=""
            name="date"
            value={props.reqValues.date}
            onChange={props.changeReqValue}
            sx={{
                width: 200,
                mr: 2,
            }}
        />
        <TextField
            id="metrics-input-limit"
            type="number"
            label="Limit"
            name="limit"
            InputProps={{
                min: 0,
                max: 100
            }}
            value={props.reqValues.limit}
            onChange={props.changeReqValue}
            sx={{
                width: 200,
                mr: 2,
            }}
        />
        <AppButton
            variant="contained"
            sx={{
                borderRadius: 10,
                textTransform: 'none',
                width: 125,
                height: 40,
                ml: 2,
            }}
            onClick={props.searchHandler}
            status={props.status}
            form_text="Search"
            disabled={props.status !== state_form}
        />
        {/* <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                width: '150px',
                ml: 2,
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    width: '42px'
                }}
            >
                Table
            </Typography>
            <Switch
                size="large"
                checked={props.reqValues.chartView}
                onChange={props.changeChartView}
            />
            <Typography
                variant="body1"
                sx={{
                    width: '42px'
                }}
            >
                Chart
            </Typography>
        </Grid> */}
    </Grid>
}