import { createStore } from "redux";
import { state_action_closesnackbar, state_action_login, state_action_logout, state_action_opensnackbar } from "./const";

var initialState = {
    url: null,
    loggedIn: false,
    userDetails: {
        token: ""
    },
    snackbar: {
        open: false,
        message: "Something went wrong",
        severity: ''
    }
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {

        case state_action_login:
            localStorage.setItem('analytics_user', JSON.stringify(action.payload))
            return {
                ...state,
                loggedIn: true,
                userDetails: action.payload,
            }

        case state_action_logout:
            localStorage.removeItem('analytics_user')
            return initialState

        case state_action_opensnackbar:
            return {
                ...state,
                snackbar: {
                    open: true,
                    message: action.payload,
                    severity: action.severity
                }
            }

        case state_action_closesnackbar:
            return {
                ...state,
                snackbar: {
                    open: false,
                    message: ""
                }
            }

        default:
            return state
    }
};

export const store = createStore(rootReducer);