import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import AppButton from '../components/button'
import { state_form } from "../const"

export default function Form(props) {

    return <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
    >
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="repeating-users-input-chart-type-label">Chart Type</InputLabel>
            <Select
                labelId="repeating-users-input-chart-type-label"
                id="repeating-users-input-chart-type"
                value={props.reqValues.chart_type}
                label="Chart Type"
                name="chart_type"
                onChange={props.changeFormValue}
            >
                <MenuItem value={'daily'}>Daily</MenuItem>
                <MenuItem value={'weekly'}>Weekly</MenuItem>
                <MenuItem value={'monthly'}>Monthly</MenuItem>
            </Select>
        </FormControl>
        <AppButton
            variant="contained"
            sx={{
                borderRadius: 10,
                textTransform: 'none',
                width: 125,
                height: 40,
                ml: 3,
            }}
            onClick={props.searchHandler}
            status={props.status}
            form_text="Search"
            disabled={props.status !== state_form}
        />
    </Grid>
}