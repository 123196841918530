import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useState } from "react"
import { state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../../const"
import ProgressBar from "../../components/progressBar"
import localAxios from "../../axios"
import { useDispatch } from "react-redux"

export default function UploadDialog(props) {
    const [status, setStatus] = useState(state_form)
    const dispatch = useDispatch()
    const [uploadProgress, setUploadProgress] = useState(0)
    const [fileName, setFileName] = useState('')
    const closeDialog = () => {
        if (status === state_loading) {
            return
        }
        props.setUploadDialogOpen(false)
    }

    const renderFilename = () => {
        if (fileName === '') {
            return <Typography variant="body1" sx={{ textAlign: 'center' }}>
                No file selected
            </Typography>
        }
        return <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {fileName}
        </Typography>
    }

    const renderProgressbar = () => {
        if (status === state_loading) {
            return <>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Uploading...
                </Typography>
                <ProgressBar value={uploadProgress} />
            </>
        }
        return <></>
    }

    const captureFile = (e) => {
        setFileName(e.target.files[0].name)
    }

    const uploadFile = () => {
        setStatus(state_loading)

        var imagefile = document.querySelector('#notif_img_input')
        let formData = new FormData();
        formData.append("file", imagefile.files[0])
        const axioxCfg = localAxios.defaults.headers.post
        const config = {
            onUploadProgress: progressEvent => {
                setUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
            },
            headers: axioxCfg,
        }
        localAxios.post(
            '/notif/upload_image',
            formData,
            config,
        )
            .then(function (response) {
                console.log(response)
                setStatus(state_success)
                setFileName('')
                props.changeFormValue({
                    target: {
                        name: 'imgUrl',
                        value: response.data.fileUrl,
                    }
                })
                closeDialog()
            })
            .catch(function (error) {
                var errMsg = ""
                if (error.response?.data) {
                    errMsg = error.response.data.message
                } else {
                    errMsg = error.message
                }
                console.log(error)
                setStatus(state_error)
                dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
            })
    }

    return <Dialog
        onClose={closeDialog}
        open={props.uploadDialogOpen}
    >
        <DialogTitle>
            Image Upload
        </DialogTitle>
        <DialogContent
            sx={{ minWidth: '400px' }}
        >
            {renderFilename()}
            <form style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Button
                    variant="outlined"
                    component="label"
                    sx={{
                        textTransform: 'none',
                    }}
                >
                    Browse for file
                    <input type="file" hidden id="notif_img_input"
                        onChange={captureFile}
                    />
                </Button>
            </form>
            {renderProgressbar()}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
            <Button
                sx={{ textTransform: 'none' }}
                onClick={closeDialog}
                disabled={status === state_loading}
            >
                Cancel
            </Button>
            <Button sx={{ textTransform: 'none' }}
                variant="contained"
                onClick={(e) => {
                    // closeDialog()
                    uploadFile()
                    // setConfirmFromDialog(true)
                    // handleBraodcast(e)
                }}
                disabled={status === state_loading}
            >
                Upload
            </Button>
        </DialogActions>
    </Dialog>
}