import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ResponseTableRow from "./tableRow";


export default function ResponseTable(props) {
    if (props.response.length === 0) {
        return <></>
    }

    return <TableContainer
        component={Paper}
        elevation={0}
        sx={{
            mt: 3,
            borderColor: 'divider',
            border: '1px solid divider'
        }}
    >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell
                        sx={{ width: 105 }}
                    >
                        Date
                    </TableCell>
                    {/* <TableCell align="center">Web Visits</TableCell>
                    <TableCell align="center">App Visits</TableCell> */}
                    <TableCell align="center">Web Unique Visitors</TableCell>
                    <TableCell align="center">App Unique Visitors</TableCell>
                    <TableCell align="center">Total User Logins</TableCell>
                    <TableCell align="center">New User Logins</TableCell>
                    <TableCell align="center">Repeat User Logins (24hours)</TableCell>
                    <TableCell align="center">Repeat User Logins (7days)</TableCell>
                    <TableCell align="center">Scanners Created</TableCell>
                    <TableCell align="center">Scans Click</TableCell>
                    <TableCell align="center">Scan Results</TableCell>
                    <TableCell align="center">Scanner Alerts Created</TableCell>
                    <TableCell align="center">Scanner Alerts Creating Users</TableCell>
                    {/* <TableCell align="center">Live Scanners Alerts</TableCell> */}
                    <TableCell align="center">Strategies Created</TableCell>
                    <TableCell align="center">Backtest Results</TableCell>
                    {/* <TableCell align="center">Backtest Results</TableCell> */}
                    <TableCell align="center">Deployments Created</TableCell>
                    <TableCell align="center">Deployment Creating Users</TableCell>
                    <TableCell align="center">Live Deployments</TableCell>
                    <TableCell align="center">Total Orders Placed</TableCell>
                    <TableCell align="center">Order Placing Users</TableCell>
                    <TableCell align="center">Paper Orders Placed</TableCell>
                    <TableCell align="center">Live Orders Placed</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.response.map((eachResponse) => (
                        <ResponseTableRow eachResponse={eachResponse} />
                    ))
                }
            </TableBody>
        </Table>
    </TableContainer>
}