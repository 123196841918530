import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { state_action_login, state_action_logout, state_action_opensnackbar, state_error, state_form, state_loading, state_success } from "../const"
import AppButton from "../components/button"
import { useDispatch } from "react-redux"
import streak_logo from '../statics/streak_logo.png'
import localAxios from "../axios"

export default function Auth() {
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [authValues, setAuthValues] = useState({
        username: '',
        password: '',
    })
    const [status, setStatus] = useState(state_form)
    const changeAuthValues = (e) => {
        setStatus(state_form)
        setAuthValues({
            ...authValues,
            [e.target.name]: e.target.value
        })
    }
    const [error, setError] = useState('')
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleLogin = (e) => {
        e.preventDefault()
        setStatus(state_loading)
        localAxios({
            method: 'post',
            url: '/auth/login',
            data: authValues,
        })
            .then(function (response) {
                setStatus(state_success)
                dispatch({ type: state_action_login, payload: response.data })
            })
            .catch(function (error) {
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    setStatus(state_error)
                    setError(error.response?.data?.message)
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                }
            })
    }


    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: '100vw',
            height: '100vh'
        }}
    >
        <Paper
            elevation={2}
            sx={{
                p: 5
            }}
        >
            <img
                src={streak_logo}
                alt="streak_logo"
                // width={100}
                style={{
                    marginTop: '-100px',
                    height: '100px',
                    filter: 'drop-shadow(5px 5px 10px #6E6E6E)'
                }}
            />
            <Typography variant="h4" sx={{ mb: 2 }}>
                Login
            </Typography>
            <Grid
                container
                direction="column"
                justifyContent="center"
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleLogin}
            >
                <TextField
                    required
                    id="auth-input-username"
                    label="Username"
                    sx={{ mt: 2 }}
                    value={authValues.username}
                    name="username"
                    onChange={changeAuthValues}
                />
                <FormControl sx={{ mt: 2 }} variant="outlined">
                    <InputLabel htmlFor="auth-input-password">
                        Password
                    </InputLabel>
                    <OutlinedInput
                        id="auth-input-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                        name="password"
                        value={authValues.password}
                        onChange={changeAuthValues}
                    />
                </FormControl>
                {
                    error ?
                        <Typography variant="body2" sx={{ mt: 2 }} color='error'>
                            {error}
                        </Typography> : <></>
                }
                <AppButton
                    variant="contained"
                    sx={{
                        mt: 4,
                        mb: 0,
                        borderRadius: 10
                    }}
                    onClick={handleLogin}
                    status={status}
                    form_text="Submit"
                    disabled={status !== state_form}
                />
            </Grid>
        </Paper>
        <Box sx={{ width: '100%', height: '150px' }} />
    </Grid>
}