import { Box } from "@mui/material";
import Users from "./users";

export default function ProMetrics() {
    return <Box
        sx={{
            textAlign: 'left',
            p: 3,
        }}
    >
        <Users />
    </Box>
}