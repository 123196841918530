import { useState } from 'react'
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from "@mui/material"
import { AccountCircle } from "@mui/icons-material"
import streak_logo from '../statics/streak_logo.png'
import { useNavigate } from 'react-router'
import { state_action_logout, state_action_opensnackbar } from '../const'
import { useDispatch } from 'react-redux'
import localAxios from '../axios'

export default function Topbar() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const closeMenu = () => {
        setAnchorEl(null)
    }
    const handleLogout = () => {
        localAxios({
            method: 'post',
            url: '/auth/logout',
        })
            .then(function (response) {
                dispatch({ type: state_action_logout, severity: 'error' })
            })
            .catch(function (error) {
                console.log("error", error)
                if (error.response?.status === 401) {
                    dispatch({ type: state_action_logout })
                } else {
                    var errMsg = ""
                    if (error.response?.data) {
                        errMsg = error.response.data.message
                    } else {
                        errMsg = error.message
                    }
                    dispatch({ type: state_action_opensnackbar, payload: errMsg, severity: 'error' })
                    dispatch({ type: state_action_logout, severity: 'error' })
                }
            })
    }


    return <AppBar
        position="fixed"
        sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: 'background.default',
            color: 'text.primary',
        }}
    >
        <Toolbar sx={{ display: 'flex' }}>
            <img
                src={streak_logo}
                alt="streak logo"
                style={{ height: '40px', marginRight: '10px', cursor: 'pointer' }}
                onClick={() => navigate('/')}
            />
            <Typography
                variant="h6"
                noWrap
                align="left"
                sx={{ flexGrow: 1, cursor: 'pointer' }}
                onClick={() => navigate('/')}
            >
                Analytics
            </Typography>
            <div>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={openMenu}
                    color="secondary"
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                >
                    <MenuItem
                        onClick={handleLogout}
                        sx={{ color: 'error.main' }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </Toolbar>
    </AppBar>
}