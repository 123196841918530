import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import UsersTableRow from "./tableRow";


export default function ResponseTable(props) {
    if (props.response.length === 0) {
        return <></>
    }

    var intervalCount = 0
    if (props.response?.length > 0) {
        intervalCount = props.response[0].cells.length
    }
    console.log(props.response[0])

    return <TableContainer
        component={Paper}
        elevation={0}
        sx={{
            mt: 3,
            borderColor: 'divider',
            border: '1px solid divider'
        }}
    >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: 130 }}>
                        Date
                    </TableCell>
                    {
                        intervalCount > 0 &&
                        props.response[0].cells.map((eachCell) => (
                            <TableCell align="center" sx={{ width: 105 }}>{eachCell.interval}</TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.response.map((eachResponse, index) => (
                        <UsersTableRow cells={eachResponse.cells} key={index} />
                    ))
                }
            </TableBody>
        </Table>
    </TableContainer>
}