import { Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { route_notifiactionsBroadcast } from "../const";
import NotificationsList from "./list";

export default function Notifications() {
    const navigate = useNavigate()

    return <Grid container spacing={2}
        sx={{
            textAlign: 'left',
            p: 3,
        }}
    >
        <Grid item xs={12} md={6} sx={{ pr: 1 }}>
            <Paper
                elevation={3}
                component={Button}
                onClick={() => navigate(route_notifiactionsBroadcast)}
                sx={{
                    textTransform: 'none',
                    p: 2,
                    display: 'block',
                    textAlign: 'left',
                    mb: 4,
                }}
            >
                <Typography variant="h6">
                    Broadcast Notifications
                </Typography>
                <br />
                <Typography variant="body1">
                    Broadcast custom notifications to users
                    with given client codes or all at once.
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ pl: 1 }}>
            <NotificationsList />
        </Grid>
    </Grid>
}