import { Button, CircularProgress } from "@mui/material";
import { state_error, state_form, state_loading, state_success } from "../const";
import { DoneAll, ErrorOutline } from "@mui/icons-material";

export default function AppButton(props) {
    const renderContent = () => {
        if (props.status === state_loading) {
            return <CircularProgress size={20} />
        } else if (props.status === state_error) {
            return <ErrorOutline />
        } else if (props.status === state_success) {
            return <DoneAll />
        } else if (props.status === state_form) {
            return props.form_text
        }
    }
    return <Button
        {...props}
    >
        {renderContent()}
    </Button>
}