import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material"
import { length_sidebarWidth, route_metrics, route_notifiactions, route_pro_metrics, route_repeatUsers } from "../const"
import { BarChart, NotificationsActive, PeopleAlt } from "@mui/icons-material"
import { useLocation, useNavigate } from "react-router"

export default function Sidebar() {
    const navigate = useNavigate()
    const location = useLocation()
    const options = [
        {
            type: 'link',
            title: 'Metrics',
            icon: <BarChart />,
            path: route_metrics
        },
        {
            type: 'link',
            title: 'Pro Metrics',
            icon: <BarChart />,
            path: route_pro_metrics
        },
        {
            type: 'link',
            title: 'Notifications',
            icon: <NotificationsActive />,
            path: route_notifiactions
        },
        {
            type: 'link',
            title: 'Repeating Users',
            icon: <PeopleAlt />,
            path: route_repeatUsers
        }
    ]
    console.log(location.pathname)

    return <Drawer
        variant="permanent"
        sx={{
            width: length_sidebarWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: length_sidebarWidth, boxSizing: 'border-box' },
        }}
    >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
            <List>
                {
                    options.map((eachOption, index) => {
                        return <ListItem key={index} disablePadding>
                            <ListItemButton
                                onClick={() => navigate(eachOption.path)}
                                sx={{
                                    color:location.pathname.startsWith(eachOption.path) ? 'primary.main' : 'inherit'
                                }}
                            >
                                <ListItemIcon
                                sx={{
                                    color:location.pathname.startsWith(eachOption.path) ? 'primary.main' : 'inherit'
                                }}
                                >
                                    {eachOption.icon}
                                </ListItemIcon>
                                <ListItemText primary={eachOption.title} />
                            </ListItemButton>
                        </ListItem>
                    })
                }
            </List>
        </Box>
    </Drawer>
}