import { useDispatch, useSelector } from "react-redux"
import { length_sidebarWidth, route_dashboard, route_metrics, route_notifiactions, route_notifiactionsBroadcast, route_pro_metrics, route_repeatUsers, state_action_login, state_action_logout } from './const'
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import Auth from "./auth"
import Lost from "./lost"
import Topbar from "./topbar"
import Sidebar from "./sidebar"
import Notifications from "./notifications"
import BroadcastNotifications from "./notifications/broadcast"
import { Box, Toolbar } from "@mui/material"
import Metrics from "./metrics"
import LocalSnackbar from "./snackbar"
import RepeatingUsers from "./repeatingUsers"
import ProMetrics from "./metricsPro"

export default function Router() {
    const dispatch = useDispatch()

    const localUserDetailsStr = localStorage.getItem('analytics_user')
    const localUserDetails = JSON.parse(localUserDetailsStr)
    var loggedIn = useSelector(state => state.loggedIn)

    if (localUserDetails) {
        dispatch({ type: state_action_login, payload: localUserDetails })
        loggedIn = true
    } else {
        dispatch({ type: state_action_logout })
    }

    const render = () => {
        if (!loggedIn) {
            return <BrowserRouter>
                <Routes>
                    <Route
                        path="*"
                        element={<Auth />}
                    />
                </Routes>
            </BrowserRouter>
        }
        return <BrowserRouter>
            <Topbar />
            <Sidebar />
            <Toolbar sx={{ mb: 2 }} />
            <Box style={{ paddingLeft: length_sidebarWidth + 10 }}>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to={route_dashboard}/>}
                    />
                    <Route
                        path={route_metrics}
                        element={<Metrics />}
                    />
                    <Route
                        path={route_pro_metrics}
                        element={<ProMetrics />}
                    />
                    <Route
                        path={route_notifiactions}
                        element={<Notifications />}
                    />
                    <Route
                        path={route_notifiactionsBroadcast}
                        element={<BroadcastNotifications />}
                    />
                    <Route
                        path={route_repeatUsers}
                        element={<RepeatingUsers />}
                    />
                    <Route
                        path=""
                        element={<Lost />}
                    />
                </Routes>
            </Box>
            <LocalSnackbar />
        </BrowserRouter>
    }

    return render()
}
